import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import App from './App.vue'
import './assets/index.postcss'
import router from './router'
import { createI18n } from 'vue-i18n'
import messages from './langs/all'
import VueSocialSharing from 'vue-social-sharing'
import { getLangParam } from './utils'


const lang = navigator.language;

export const i18n = createI18n({
  locale: getLangParam(), // set locale
  allowComposition: true, // you need to specify that!
  fallbackLocale: 'en', // set fallback locale
  messages:messages
})

const head = createHead()
const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
app.use(pinia)
app.use(router)
app.use(head)
app.use(i18n)
app.use(VueSocialSharing)
app.mount('#app')

