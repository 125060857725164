import axios, { AxiosError } from "axios";
import type { WalletOption } from "./wallets";
import { CustomError } from "ts-custom-error";

const endpoint_invite = "https://worker-minthe-invite-link.minthelab.workers.dev/"
const endpoint_invite_count = "https://worker-ornn-invitation-visit-count.minthelab.workers.dev/"

export class UseInvitionError extends CustomError {
    public constructor(
        public code: number,
        message?: string,
    ) {
        super(message)
    }
}


export type InvitionData = {
    id_byte: number,
    pubkey: string,
}
export type InvitionDataEx = InvitionData & { last_code: string }

function processAxiosError(error:unknown):Error{
    if(error instanceof AxiosError){
        if (error.response) {
            if (error.response.status === 404) {
                return new UseInvitionError(404, "This Invitation doesn't exist in database ");
            } else {
                return new UseInvitionError(error.response.status, `HTTP Request Error: [CODE ${error.response.status}] : ${error.response.statusText}`);
            }
        } else if (error.request) {
            return new UseInvitionError(-1, "The request was made but no response was received");
        } 
    }
    return new UseInvitionError(-1, `Error: ${error}`);
}

export async function getInvitionDataByCode(code: string): Promise<InvitionDataEx> {
    if (code.length != 6) {
        throw new UseInvitionError(404, "Code is illegal")
    }
    try {
        let req = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "get-data": code
            },
            url: endpoint_invite
        });
        return req.data;
    } catch (error) {
        throw processAxiosError(error)
    }
}

export async function getInvitionDataByPubkey(pubkey: string): Promise<InvitionDataEx> {
    try {
        let req = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "get-data-by-key": pubkey
            },
            url: endpoint_invite
        });
        return req.data;
    } catch (error) {
        throw processAxiosError(error)
    }
}

export async function checkInvitionExistByPubkey(pubkey: string) {
    try{
        let result = await getInvitionDataByPubkey(pubkey)
        return result.last_code
    }catch{
        
    }
    return ''
}

export async function increaseInvitionVisitCount(code:string,increase = true) {
    if(code!=""){
        try {
            let req = await axios({
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    "code": code,
                    'increase':increase
                },
                url: endpoint_invite_count
            });
            return req.data;
        } catch (error) {
            throw processAxiosError(error)
        }
    }
}


export async function putInvitionData(data: InvitionData): Promise<string> {
    try {
        let req = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "put-data": JSON.stringify(data)
            },
            url: endpoint_invite
        });
        const result = req.data;
        return result;
    } catch (error) {
        throw processAxiosError(error)
    }
}
