import { createRouter, createWebHistory,RouterView } from 'vue-router'
import IndexPage from '@/pages/IndexPage.vue'
import ClaimPage from '@/pages/ClaimPage.vue'
import TokenManagePage from '@/pages/TokenManagePage.vue'
import DemoPage from '@/pages/DemoPage.vue'
import { isDev, useStore } from '@/store'
import NotFoundPage from '@/pages/NotFoundPage.vue'
export const routes = [
  {
    path: '/:lang?',
    component: IndexPage,
    meta: {
      id:"index"
    },
  },
  {
    path: '/:lang?/claim',
    component: ClaimPage,
    meta: {
      id:"claim"
    },
  },
  {
    path: '/:lang?/manage',
    component: TokenManagePage,
    meta: {
      id:"manage"
    },
  }
]

if(isDev()){
  routes.push(  {
    path: '/:lang?/admin/',
    component: DemoPage,
    meta: {
      id:"admin"
    },
  })
}
routes.push({
  path:"/:pathMatch(.*)*",
  component:NotFoundPage,
  meta:{
    id:"404"
  }
})


const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to,from,next)=>{
  if (Object.prototype.hasOwnProperty.call(from.query, 'c')) {
    if(from.query['c']&&!Object.prototype.hasOwnProperty.call(to.query, 'c')){
      useStore().use_invite_code = from.query['c'] as string
      router.push({
        path:to.path,
        query:{
          c:from.query['c'] as string
        }
      })
      console.log(`store invite code: ${from.query['c']}`)
    }
  }
  next();
})



export default router
