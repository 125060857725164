export const zh = {
  "errors": {
    "noWalletAvailable": "请先连接一个Solana钱包！",
    "creationError": "创建{tokenName}时出错：{errorMessage}",
    "invalidSupply": "供应量格式错误，必须是一个整数或小数",
    "emptyTokenNameOrSymbol": "注意！您正在创建一个代币全称或代币简称为空的代币信息！"
  },
  "messages": {
    "uploading": "上传中....",
    "uploadError": "上传错误：{errorMessage}",
    "uploadComplete": "上传完成！",
    "wellDoneCreateToken": "干得好！代币 {symbol} 已创建！",
    "metadataNoExtension": "如果您想添加扩展，请点击添加按钮，将会添加扩展",
    "metadataKeepCreatorNoneTip": "如果您不想在代币信息中添加任何创建者信息，请将它们都保留为无文本",
    "inviteLinkNotExistBefore": "您当前使用的邀请链接无效，在<a class='underline' href='https://twitter.com/search?q=%24Minthe&src=cashtag_click'>这里</a>找到一个有效邀请链接",
    "inviteLinkNotUseBefore": "我们注意到您没有使用邀请链接访问，在<a class='underline' href='https://twitter.com/search?q=%24Minthe&src=cashtag_click'>这里</a>找到一个邀请链接",
    "createTokenResultModel": {
      "tip1": "这是访问您创建的代币的账户公钥。",
      "tip2": "请将其保存，因为它将是未来创建更多代币的凭证！"
    }
  },
  "titles": {
    "solanaTokenCreator": "代币创建器",
    "claimCollector": "领取您的奖励",
    "createMetadata": "创建代币信息",
    "faq": "常见问题解答",
    "tokenManage": "代币权限管理",
  },
  "descriptions": {
    "solanaTokenCreator": "可靠、用户友好且快速。",
    "claimCollector": "通过推广来盈利。",
    "faq": "",
    "tokenManage": "管理你的已创建代币",
    "connectWallet": "连接您的 Solana 钱包以继续",
  },
  "actions": {
    "debugUseTestForm": "调试：使用测试表单",
    "upload": "上传",
    "edit": "编辑",
    "createToken": "创建 {tokenSymbol} 代币",
    "updateMetadata": "更新代币信息",
    "copy": "复制到剪贴板",
    "goSolscan": "前往Solscan",
    "checkInviteLink": "检查您创建的链接",
    "createLink": "创建新链接",
    "fetchBalance": "获取反佣余额",
    "claim": "领取！",
    "switchTo":"切换至",
    "revoke":"撤销",
    "disconnect":"断开连接",
  },
  "labels": {
    "tokenName": "代币全称",
    "claimAccountInfo": "账户信息",
    "tokenSymbol": "代币简称",
    "tokenDecimals": "精度",
    "tokenSupply": "供应量",
    "tokenMetadataURI": "代币代币信息",
    "metadataImage": "图片",
    "metadataDescription": "描述",
    "metadataExtensions": "扩展",
    "metadataCreatorInfo": "创建者信息",
    "metadataCreatorName": "创建者名称",
    "metadataCreatorSite": "创建者网站",
    "metadataTags": "标签",
    "tokenInfo": "代币信息",
    "createInviteLink": "创建邀请链接",
    "amount": "数量",
    "tokenAddress": "代币地址",
    "selectAuth": "选择权限",
    "tokenType": "代币类型",
    "advancedOptions": "进阶选项",
    "revokeMintAuth": "丢弃铸造权限",
    "revokeAuth": "丢弃权限",
    "revokeFreezeAuth": "丢弃冻结权限",
    "updateMetadata": "更新代币信息",
    "mintToken": "铸造代币"
  },
  "hints": {
    "tokenName": "您的代币名称",
    "tokenSymbol": "代币符号是代币的简称",
    "tokenDecimals": "代币的最小细分单位（小数点的位数）",
    "tokenSupply": "确定您的代币供应量",
    "tokenMetadataURI": "指向您的代币信息url，您也可以点击上传来交互式创建代币信息",
    "claimAccountInfo": "您的奖励会实时更新，并且可以随时领取",
    "metadataImage": "在这里上传您代币LOGO的图片",
    "metadataDescription": "",
    "metadataExtensions": "",
    "metadataNewTag": "新标签名称",
    "createInviteLink": "任何使用您链接创建Solana代币的人，您将获得~0.01 SOL的返佣奖励！",
    "youAccountHave": "您的账户有",
    "canBeclaimed": "可以被领取！",
    "tokenAddress": "输入您的代币地址",
    "tokenType": "选择你要使用的代币程序"
  },
  "nouns": {
    "metadata": "代币信息",
    "or": "或",
    "connect": "连接",
    "yourWallet": "您的Solana钱包（支持Phantom和Solflare）"
  },
  "article": {
    "tokenCreatorDesc": {
      "title": "Minthe Solana 代币创建器",
      "para1": "在几秒内创建您的Solana SPL 代币，<span class='font-bold text-pink-600'>仅需0.09 SOL</span>。"
    },
    "tokenCreatorUsage": {
      "title1": "示例代币",
      "t1p1": "您可以在区块浏览器或RugCheck检查使用该工具创建的测试代币",
      "t1l1": "Token-program测试代币",
      "t1l2": "Token-program测试代币（撤销权限）",
      "t1l3": "Token-program-2022测试代币",
      "t1l4": "Token-program-2022测试代币（撤销权限）",
      "title2": "如何使用",
      "t2p1": "根据描述填写表单。",
      "t2p2": "点击“创建代币”并在您的钱包中批准交易。",
      "t2p3": "恭喜！您现在可以在Solscan和您的钱包中检查您的代币。",
      "t2p4": "如果您还有疑问，可以前往<a href='#faq' class='underline text-pink-700'>FAQ</a>或查看我们的详细文档。",

      "title3": "推荐这个工具",
      "t3p1": "通过“创建邀请链接”可以获得一个独特的邀请链接。使用您的邀请链接创建代币将获得10%的折扣，您也将在每一个代币创建时获得约0.01 SOL的奖励。",

      "title4": "联系我们",
      "t4p1": "通过以下链接加入我们的社交群组。",

      "title5": "关于我们",
      "t5p1": "我们后续即将上线多种不同机制的代币，如燃烧，分红，抽奖等机制，以及增添其他功能，如私募，锁仓，锁定流动性等。如有用户需要上述功能，或有其他需求，欢迎加入我们的telegram群组私聊客服。"

    }
  },
  "FAQ": {
    "q1": "成本是多少？",
    "q1a1": "创建一个代币的费用约为0.09 SOL，但您可以通过邀请链接获得10%的折扣。",
    "q1a2": "请注意，使用高级选项会导致稍高的费用。",
    "q2": "Token Program和Token Program 2022之间有什么区别？",
    "q2a1": "简而言之，如果您没有特定的要求，请使用Token Program。",
    "q2a2": "它们都是Solana维护的官方代币标准。Token Program2022是在Token-program上的向后兼容扩展集。",
    "q2a3": "Token Program具有最大的兼容性，但功能较少",
    "q2a4": "Token Program 2022具有一些额外的功能，但面临兼容性问题。例如，Solflare钱包不支持Token Program 2022。",
    "q2a5": "您可以查看<a href='https://spl.solana.com/token-2022/extensions' class='underline'>这份文档</a>，了解Token-program2022支持的扩展列表。",
    "q3": "这个工具安全吗？我对创建的代币有什么权限？",
    "q3a1": "我们不会保留您创建的代币的任何权限。您对您的代币拥有完全的控制权，包括铸造、冻结账户和更新代币信息。您可以通过在Solscan上查看您的代币来确认这一点。",
    "q4": "如何丢弃铸造权限或冻结权限？",
    "q4a1": "在创建代币时，您可以检查“丢弃铸造权限”选项或“丢弃冻结权限”选项以撤销相应的权限。",
    "q4a2": "如果您已经创建了代币，可以前往<a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>代币管理</a>标签页撤销权限。",
    "q5": "如何铸造更多代币？",
    "q5a1": "如果您的代币具有铸币权限，即非固定供应量，您可以在<a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>代币管理</a>标签页铸造更多代币。",
    "q6": "如何更新代币代币信息？",
    "q6a1": "您可以在<a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>代币管理</a>标签页更新代币的代币信息。"
  },
  "SiteMeta": {
    "ogTitle": {
      "index": "Minthe: Solana 代币创建者",
      "claim": "Minthe: 领取回馈",
      "admin": "Minthe: 管理员游乐场",
      "404": "Minthe: 404 Not Found",
      "manage": "Minthe: 代币管理"
    },
    "tabBarTitle": {
      "index": "创建 Solana 代币",
      "claim": "领取回馈",
      "admin": "管理员游乐场",
      "404": "-",
      "manage": "代币管理"
    },
    "ogDescription": "Minthe: 一站式Solana发币解决方案",
    "twitterTitle": "Minthe: 一站式Solana发币解决方案",
  }
}
