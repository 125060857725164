import * as walletBase from "@solana/wallet-adapter-base";
import { useStore } from '@/store';
import {
    SolflareWalletAdapter,
    PhantomWalletAdapter
} from "@solana/wallet-adapter-wallets";

import { TOKEN_PROGRAM_ID, TOKEN_2022_PROGRAM_ID, getMint, getTokenMetadata } from "@solana/spl-token";
import init, { SplTokenType, delegator_account_len, RevokableAuthority, minthe_program_id } from "libminthe/libminthe";
import { MintheClient, CreateTokenOutput } from "libminthe/libminthe";
import { Message, Connection, PublicKey, Transaction, ComputeBudgetProgram, RpcResponseAndContext, SignatureResult } from "@solana/web3.js"
import { UseInvitionError, getInvitionDataByCode } from "./invite";
import * as mpl from "@metaplex-foundation/mpl-token-metadata"
import * as umi from "@metaplex-foundation/umi"
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
export type WalletOption = {
    adapter: walletBase.BaseMessageSignerWalletAdapter,
    name: WalletNames,
    network: walletBase.WalletAdapterNetwork
}
export {RevokableAuthority}
type WalletNames = "Solflare" | "Phantom"

export function getConnection(net: walletBase.WalletAdapterNetwork): Connection{
    if (net === "devnet") {
        return new Connection("https://solana-devnet.g.alchemy.com/v2/4B4Vu7Vyb-wP5PcVYvmM4ebbROGzlmbd")
    } 
    
    return new Connection("https://solana-mainnet.g.alchemy.com/v2/SBodBeywLt5smxYIIYbrG4mIIlHbA45t")
}

function generateWalletByName(name: WalletNames, network: walletBase.WalletAdapterNetwork) {
    const wallet_map = {
        "Solflare": new SolflareWalletAdapter({ network }),
        "Phantom": new PhantomWalletAdapter()
    }
    return wallet_map[name as WalletNames] as walletBase.BaseMessageSignerWalletAdapter
}

export function generateWallets(is_debug: boolean) {
    let wallets: Array<WalletOption> = [];
    const generate_wallets_with_network = (network: walletBase.WalletAdapterNetwork) => {
        wallets = (["Solflare", "Phantom"]).map((value) => {
            return {
                adapter: generateWalletByName(value as WalletNames, network),
                name: value as WalletNames,
                network
            }
        })
    }
    generate_wallets_with_network(is_debug ? walletBase.WalletAdapterNetwork.Devnet : walletBase.WalletAdapterNetwork.Mainnet)
    return wallets;
}

export async function connectWallet(wallet: WalletOption) {
    await wallet.adapter.connect()
    if (useStore().minthe_context == undefined) {
        if (wallet.adapter.publicKey) {
            await init()
            useStore().minthe_context = new MintheClient(wallet.adapter.publicKey.toBase58())
            await fetchRecommenderAndSet()
        }
    }
}
export async function disconnectWallet(wallet: WalletOption) {
    await wallet.adapter.disconnect()
}

export async function switchWalletNetwork(wallet: WalletOption) {
    const dest_network = wallet.network === walletBase.WalletAdapterNetwork.Devnet ? walletBase.WalletAdapterNetwork.Mainnet : walletBase.WalletAdapterNetwork.Devnet
    await wallet.adapter.disconnect()
    wallet.adapter = generateWalletByName(wallet.name, dest_network)
    await wallet.adapter.connect()
    wallet.network = dest_network
}

export type SendTransactionConfig = {
    progress?(progress: number): void
}

export type createInterfaceArgs = {
    token_name: string,
    symbol: string,
    url: string,
    supply: string,
    decimals: number,

    token_type: SplTokenType,
    revoke_mint_tokens: boolean,
    revoke_freeze_account: boolean,
    create_delegate: boolean
} & SendTransactionConfig

export type MintInterfaceArgs = {
    mint: string,
    amount: bigint,
} & SendTransactionConfig

export type UpdateInterfaceArgs = {
    mint: string,
    token_name: string,
    symbol: string,
    url: string,
} & SendTransactionConfig

export type RevokeInterfaceArgs = {
    mint: string,
    authority: RevokableAuthority 
} & SendTransactionConfig

async function sendTransaction(connection: Connection, wallet: WalletOption, msg: Uint8Array, config?: SendTransactionConfig) {
    let message = Message.from(msg)

    let fees = await connection.getRecentPrioritizationFees()
    let fee = fees.map((v) => BigInt(v.prioritizationFee)).reduce((p, c) => p + c) / BigInt(fees.length)
    fee = fee < BigInt(100000) ? BigInt(100000) : fee;

    let tx = new Transaction()
    const store = useStore()
    const scaledFee = fee * (store ? BigInt(store.use_fee_scalar) : BigInt(1));
    console.log(`Sending Trascation with fee: ${scaledFee}`)
    tx.add(ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: scaledFee
    }));
    tx.add(Transaction.populate(message))
    let blockhash = await connection.getLatestBlockhash()
    tx.recentBlockhash = blockhash.blockhash;
   
    let blockheight = await connection.getBlockHeight();

    console.log("latest valid block hash is " + blockhash.lastValidBlockHeight);
    try {
        let resp = await wallet.adapter.sendTransaction(tx, connection);

        let finished = false;
        let tasks: [Promise<RpcResponseAndContext<SignatureResult>> | Promise<void>] = [
            (async () => {
                let result = await connection.confirmTransaction({
                    blockhash: blockhash.blockhash,
                    lastValidBlockHeight: blockhash.lastValidBlockHeight,
                    signature: resp,
                }, "confirmed");
                finished = true;
                return result
            })()
        ];

        if (config && config.progress) {
            tasks.push((async () => {
                while (!finished) {
                    await new Promise(r => setTimeout(r, 1000));
                    let height = await connection.getBlockHeight("confirmed");
                    if (height > blockhash.lastValidBlockHeight) {
                        await new Promise(() => {})
                    }
                    let progress = (height - blockheight) / (blockhash.lastValidBlockHeight + 32 - blockheight) * 100
                    config!.progress!(progress)
                };
                config!.progress!(0)
            })())
        }
        
        let result = await Promise.race(tasks)
        if (result && result.value.err) {
            throw result.value.err
        }
    } catch (e: any) {
        if (!(e instanceof walletBase.WalletError)) {
            throw e
        }

        throw e.name
    }
}

export async function fetchRecommenderAndSet() {

    const route = useStore().route
    let code = route.query['c'] as string
    if(!code){
        if(useStore().use_invite_code == ''){
            return
        }else{
            code = useStore().use_invite_code
        }

    }
    try{
        let data = await getInvitionDataByCode(code)
        const context = useStore().minthe_context
        if(!context||!data){
            return
        }
        console.log(`fetch data with code:${code},and set recommander:${data.pubkey}`)
        context.recommender = data.pubkey
    }catch(e){
        console.error(e)
        if(e instanceof UseInvitionError){
            if(e.code === 404){
                useStore().invite_code_not_exists = true
            }
        }
    }
}


export async function createToken(wallet: WalletOption, args: createInterfaceArgs): Promise<string> {
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context
    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58()
    let supply = BigInt(args.supply.replace(".", ""));
    if (supply >= BigInt(2) ** BigInt(64)) {
        throw new Error("SupplyOverflow")
    }

    const result: CreateTokenOutput = context.create_token(
        args.token_name, 
        args.symbol, 
        args.url, 
        args.decimals, 
        supply,
        args.token_type,
        args.revoke_mint_tokens,
        args.revoke_freeze_account,
        args.create_delegate
    )
    // check the mint address if it exists
    let mint_pda = new PublicKey(result.mint);
    let account = await connection.getAccountInfo(mint_pda)
    if (account != null) {
        throw new Error("TokenAddressExists")
    }
    
    // send the transaction
    await sendTransaction(connection, wallet, result.message, args)
    
    return result.mint
}

export async function getClaimableBalance(wallet: WalletOption): Promise<[number, Uint8Array]> {
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58();
    let result = context.claim_delegated()
    let minimal_balance = await connection.getMinimumBalanceForRentExemption(delegator_account_len())
    let account_balance = await connection.getBalance(new PublicKey(result.delegated))
    let claimable = 0;
    
    if (account_balance > minimal_balance) {
        claimable = account_balance - minimal_balance;
    }

    let delegate = await connection.getAccountInfo(new PublicKey(result.delegated))
    if (!delegate || delegate.owner.toBase58() !== minthe_program_id()) {
        throw new Error("NotEligibleForClaiming")
    }

    console.log(result.delegated + " " + publicKey.toBase58() + " claimable balance: " + claimable)
    return [claimable, result.message]
}

export async function claimBalance(wallet: WalletOption) {
    let connection = getConnection(wallet.network)
    let [claimable, msg] = await getClaimableBalance(wallet);
    console.log("handle claim balance")
    if (claimable === 0) {
        return
    }

    await sendTransaction(connection, wallet, msg);
}

export async function createInviteLink(wallet: WalletOption) {
    let connection = getConnection(wallet.network)
    const publicKey = useStore().activate_wallet?.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58();
    let result = context.create_delegate();
    // perform checks
    let delegate = await connection.getAccountInfo(new PublicKey(result.delegated))
    if (delegate) {
        if (delegate.owner.toBase58() !== minthe_program_id()) {
            throw new Error("AccountInvalidForDelegate")
        }
        // already created
        return
    }
    console.log(result.message)
    await sendTransaction(connection, wallet, result.message)
}

export async function getTokenType(connection: Connection, mint: PublicKey): Promise<SplTokenType> {
    let mint_info = await connection.getAccountInfo(mint)
    if (!mint_info) {
        throw new Error("MintNotExist")
    }
    console.log(mint_info)
    if (mint_info.owner.equals(TOKEN_PROGRAM_ID)) {
        return SplTokenType.Token
    } else if (mint_info.owner.equals(TOKEN_2022_PROGRAM_ID)) {
        return SplTokenType.Token2022
    } else {
        throw new Error("NotSplToken")
    }
}

export async function updateMetadata(wallet: WalletOption, args: UpdateInterfaceArgs) {
    console.log(args)
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58()
    let mint_addr = new PublicKey(args.mint)
    let token_type = await validateUpdateMetadata(connection, publicKey, mint_addr)

    const result = context.update_metadata(args.mint, args.token_name, args.symbol, args.url, token_type)
    // check the mint address if it exists
    let mint_pda = new PublicKey(args.mint);
    let supply = await connection.getAccountInfo(mint_pda)
    if (supply == null) {
        throw new Error("TokenNotExist")
    }
    
    // send the transaction
    await sendTransaction(connection, wallet, result, args)
}

export async function validateUpdateMetadata(connection: Connection, payer: PublicKey, mint_addr: PublicKey): Promise<SplTokenType> {
    let token_type = await getTokenType(connection, mint_addr)
    // check if we have the permission to update metadata
    if (token_type === SplTokenType.Token2022) {
        let mint = await getTokenMetadata(connection, mint_addr)
        if (!mint) {
            throw new Error("MintNotExist")
        }

        if (!mint.updateAuthority?.equals(payer)) {
            throw new Error("NotAuthorizedToUpdate")
        }
    }
    
    let umiInstance = createUmi(connection);
    let metadata_pda = mpl.findMetadataPda(umiInstance, { mint: umi.publicKey(mint_addr) })
    let meta = await mpl.fetchMetadata(umiInstance, metadata_pda)
    if (meta.updateAuthority.toString() !== payer.toBase58()) {
        throw new Error("NotAuthorizedToUpdate")
    }

    return token_type
}

export async function revokeAuthority(wallet: WalletOption, args: RevokeInterfaceArgs) {
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58()
    let token_type = await validateRovkeAuthority(connection, publicKey, new PublicKey(args.mint), args.authority)
    
    const result = context.revoke_authority(args.mint, args.authority, token_type)
    // check the mint address if it exists
    let mint_pda = new PublicKey(args.mint);
    let supply = await connection.getAccountInfo(mint_pda)
    if (supply == null) {
        throw new Error("TokenNotExist")
    }

    // send the transaction
    await sendTransaction(connection, wallet, result, args)
}

export async function validateRovkeAuthority(connection: Connection, payer: PublicKey, mint_addr: PublicKey, authority: RevokableAuthority): Promise<SplTokenType> {
    // we want to check the type of mint
    let token_type = await getTokenType(connection, mint_addr)
    let mint = await getMint(connection, mint_addr, undefined, tokenProgramId(token_type))
    
    if (authority == RevokableAuthority.FreezeAccount) {
        if (!mint.freezeAuthority) {
            throw new Error("AlreadyRevoked");
        } else if (!mint.freezeAuthority.equals(payer)) {
            throw new Error("NotAuthorizedToRevoke")
        }
    }

    if (authority == RevokableAuthority.MintTokens) {
        if (!mint.mintAuthority) {
            throw new Error("AlreadyRevoked");
        } else if (!mint.mintAuthority.equals(payer)) {
            throw new Error("NotAuthorizedToRevoke")
        }
    }

    return token_type
}

export async function withdrawFee(wallet: WalletOption) {
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58()
    const result = context.withdraw_fee();

    // send the transaction
    await sendTransaction(connection, wallet, result)
}

export async function mintTokens(wallet: WalletOption, args: MintInterfaceArgs) {
    let connection = getConnection(wallet.network)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw new Error("WalletNotConnected")
    }

    context.payer = publicKey.toBase58()
    // check if the mint has a mint authority and it is the current payer
    let mint_addr = new PublicKey(args.mint) 
    let [token_type, decimals] = await validateMintTokens(connection, publicKey, mint_addr)

    const result = context.mint_token(args.mint, args.amount * BigInt(10)**BigInt(decimals), token_type);

    // send the transaction
    await sendTransaction(connection, wallet, result, args)
}

export async function validateMintTokens(connection: Connection, payer: PublicKey, mint_addr: PublicKey): Promise<[SplTokenType, number]> {
    let token_type = await getTokenType(connection, mint_addr);
    let mint = await getMint(connection, mint_addr, undefined, tokenProgramId(token_type))
    if (!mint.mintAuthority) {
        throw new Error("MintAuthorityRevoked")
    }

    if (!mint.mintAuthority.equals(payer)) {
        console.log(payer.toBase58() + " mint authority: " + mint.mintAuthority.toBase58())
        throw new Error("NotAuthorizedToMint")
    }

    return [token_type, mint.decimals]
}

function tokenProgramId(ty: SplTokenType): PublicKey {
    if (ty == SplTokenType.Token) {
        return TOKEN_PROGRAM_ID
    } else if (ty == SplTokenType.Token2022) {
        return TOKEN_2022_PROGRAM_ID
    } else {
        throw new Error("InvalidTokenType")
    }
}
