export const en = {
  "errors": {
    "noWalletAvailable": "Please connect a Solana wallet first!",
    "creationError": "Error on create {tokenName}: {errorMessage}",
    "invalidSupply": "Invalid supply format, it must be an integer or a decimal",
    "emptyTokenNameOrSymbol":"Attention! You are creating metadata with Token's NAME or SYMBOL which are empty text!"
  },
  "messages": {
    "uploading": "Uploading....",
    "uploadError": "Error on uploading: {errorMessage}",
    "uploadComplete": "Upload complete!",
    "wellDoneCreateToken":"Well done! Token {symbol} created!",
    "metadataNoExtension":"If you want to add extension data, please click add button",
    "metadataKeepCreatorNoneTip":"if you dont't want to add any creatorinfo in metadata, please keep them empty",
    "inviteLinkNotExistBefore":"You are using a invalid invite link, find a valid one <a class='underline' href='https://twitter.com/search?q=%24Minthe&src=cashtag_click'>here</a>",
    "inviteLinkNotUseBefore":"We offer a 10% discount for users with a invite link, find one <a class='underline' href='https://twitter.com/search?q=%24Minthe&src=cashtag_click'>here</a>",
    "createTokenResultModel":{
      "tip1":"This is the address for accessing the token you created.",
      "tip2":""
    }
  },
  "titles": {
    "solanaTokenCreator": "Token Creator",
    "claimCollector": "Claim your rewards",
    "createMetadata": "Create MetaData",
    "faq":"Frequently Asked Questions",
    "tokenManage":"Token Manager",
  
  },
  "descriptions": {
    "solanaTokenCreator": "Solana Token Made Easy!",
    "claimCollector": "Earn by promoting.",
    "faq":"",
    "tokenManage":"Manage your Solana token",
    "connectWallet": "Connect a wallet on \n Solana to continue",
  },
  "actions": {
    "debugUseTestForm": "Debug: Use Test Form",
    "upload":"Upload",
    "edit":"Edit",
    "createToken":"Create {tokenSymbol} Token",
    "updateMetadata":"Update Metadata",
    "copy":"Copy to Clipborad",
    "goSolscan":"Go Solscan",
    "checkInviteLink":"Check Your Link",
    "createLink":"Create a New Link",
    "fetchBalance":"Fetch Balance for Account",
    "claim":"Claim!",
    "switchTo":"switch to",
    "revoke":"revoke",
    "disconnect":"disconnect",
  },
  "labels": {
    "tokenName": "Token Name",
    "claimAccountInfo": "Account Info",
    "tokenSymbol": "Symbol",
    "tokenDecimals": "Decimals",
    "tokenSupply": "Supply",
    "tokenMetadataURI": "Token Metadata",
    "metadataImage":"Image",
    "metadataDescription":"Description",
    "metadataExtensions":"Extensions",
    "metadataCreatorInfo":"Creator Info",
    "metadataCreatorName":"Creator Name",
    "metadataCreatorSite":"Creator Site",
    "metadataTags":"Tags",
    "tokenInfo":"Token Info",
    "createInviteLink":"Create Invite Link",
    "amount":"Amount",
    "tokenAddress":"Token Address",
    "selectAuth":"Select Authority",
    "tokenType":"Token Type",
    "advancedOptions":"Advanced Options",
    "revokeMintAuth":"Revoke Mint Authority",
    "revokeAuth":"Revoke Authority",
    "revokeFreezeAuth":"Revoke Freeze Authority",
    "updateMetadata":"Update Metadata",
    "mintToken":"Mint Token"
  },
  "hints": {
    "tokenName": "The name of your token",
    "tokenSymbol": "Your token's identifying abbreviation",
    "tokenDecimals": "Decimals refer to how divisible a token can be",
    "tokenSupply": "The total number of tokens minted to your wallet after creation",
    "tokenMetadataURI": "Paste your url here or create & upload with a UI",
    "claimAccountInfo": "Your rewards is updated in real time and you can claim at any time.",
    "metadataImage":"Upload your token's image here",
    "metadataDescription":"",
    "metadataExtensions":"",
    "metadataNewTag":"New Tag Name",
    "createInviteLink":"Whenever someone creates Solana Token in Minthe with your invite link, you will get <span class='font-bold text-pink-600'>~0.01 SOL</span> as rewards!",
    "youAccountHave":"Your account have",
    "canBeclaimed":"Can be claimed!",
    "tokenAddress":"Type your token address",
    "tokenType":"Just use the default one or take a look at <a href='#diff22' class='underline text-pink-700'>the difference</a>"
  },
  "nouns":{
    "metadata":"Metadata",
    "or":"OR",
    "connect":"Connect",
    "yourWallet":"your Solana wallet (both Phantom and Solflare are supported)"
  },
  "article": {
    "tokenCreatorDesc": {
      "title":"Minthe Solana Token Creator",
      "para1":"Create your Solana SPL Token in seconds for <span class='font-bold text-pink-600'>only 0.09 SOL</span>.",
    },
    "tokenCreatorUsage": {
      "title1": "Example Tokens",
      "t1p1": "Here are some example tokens created by Minthe.",
      "t1l1": "Token Program Test Token",
      "t1l2": "Token Program Test Token  (Authorities Revoked)",
      "t1l3": "Token Program-2022 Test Token",
      "t1l4": "Token Program-2022 Test Token (Authorities Revoked)",

      "title2": "How to Use",
      "t2p1": " Fill the form according to the description.",
      "t2p2": " Click \"Create Token\" and approve the transaction in your wallet.",
      "t2p3": " Congratulations! Your can check your token in Solscan and your wallet now.",
      "t2p4": " If you still have questions, go to <a href='#faq' class='underline text-pink-700'>FAQ</a> or check out our detailed ducumantation.",
      
      "title3": "Recommend This Tool",
      "t3p1": "You can obtain a unique invitation link by checking \"Create Invite Link.\" Creating tokens using your invitation link will result in a <span class='text-pink-700'>10%</span> discount, and you will also receive a reward of <span class='text-pink-700'>~0.01 SOL</span>.",

      "title4":"Contact Us",
      "t4p1":"Join our social group through the following link.",

      "title5":"About Us",
      "t5p1":"We aim to offer professional token management services, which include mechanisms such as burning, dividends, lotteries, presales, token locking, LP locking, etc. If you require any of these functionalities or have other specific requirements, please feel free to join our Telegram group and privately chat with our customer service representatives."
    },
  },
  "FAQ": {
    "q1": "How much does it cost?",
    "q1a1": "The fee for creating a token is ~0.09 SOL, and you can get a 10% discount with a invitation link.",
    "q1a2": "Note that using Advance Options results in a slightly higher fee.",
    "q2": "What's the difference between Token Program and Token Program 2022",
    "q2a1": "TL;DR, Use Token Program if you do not have specific requirements.",
    "q2a2": "They are both official token standards maintained by Solana. Token Program 2022 is a back compatible extension set over Token Program.",
    "q2a3": "Token Program has maximum compatibility but comes with less functionalities",
    "q2a4": "Token Program 2022 has some amazing features but faces compatibility issues. For example,  it is not supported by solflare wallet.",
    "q2a5": "You can check <a href='https://spl.solana.com/token-2022/extensions' class='underline'>this documentation</a> for a list of extensions supported by Token Program 2022.",
    "q3": "Is this tool safe? What permission do I have over the created token?",
    "q3a1": "We will not retain any permission over the token you create. You have full control over your token including minting, freezing accounts, and updating metadata. You can confirm this by viewing your token on Solscan.",
    "q4": "How can I revoke mint authority or freeze authority",
    "q4a1": "You can check \"Revoke Mint Authority\" option or \"Revoke Freeze Authority\" option to revoke the corresponding authority when creating token.",
    "q4a2": "If you have already created your token, you can go to <a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>Token Manager</a> tab to revoke authorities.",
    "q5": "How to mint more token?",
    "q5a1": "If your token has a mint authority, i.e. not fixed supply, you can mint more token in the <a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>Token Manager</a> tab.",
    "q6": "How to update token metadata?",
    "q6a1": "You can update the metadata of your token in the <a onClick=\"window.router.push('/manage/')\" class='underline hover:text-white transition-colors cursor-pointer'>Token Manager</a> tab."
  },
  "SiteMeta":{
    "ogTitle":{
      "index":"Minthe: Solana Token Creator",
      "claim":"Minthe: Claim Rewards",
      "admin":"Minthe: Admin Playground",
      "404":"Minthe: 404 Not Found",
      "manage":"Minthe: Token Manage"
    },
    "tabBarTitle":{
      "index":"Token Creator",
      "claim":"Claim Rewards",
      "admin":"Admin Playground",
      "404":"-",
      "manage":"Token Manage"
    },
    "ogDescription":"Solana Token Made Easy!",
    "twitterTitle":"Minthe: ",
  }
}
