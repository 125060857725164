import { Ref } from "vue";

export function copyTextToClipBoard(text:string) {
    navigator.clipboard.writeText(text)
}

export interface IActionUICallback {
    message(msg: string): void;
    running(isRunning: boolean,progress?:number): void;
}

export class ActionCallback implements IActionUICallback{
    private em:Ref<string> 
    public r:Ref<boolean> 
    private p:Ref<number>
    constructor(em:Ref<string>,r:Ref<boolean>,p:Ref<number>){
        this.em = em;
        this.r = r;
        this.p = p;
    }
    public message(msg:string){
        this.em.value = msg
    }
    public running(is_running:boolean,progress?:number){
        this.r.value = is_running
        if(progress){
            this.p.value = progress
        }

    }
}

export function getLangParam(){
    const href = window.location.href
    if(href.indexOf("/en")!=-1){
      return 'en'
    }else if(href.indexOf("/zh")!=-1){
      return 'zh'
    }
    return navigator.language.startsWith('zh') ? 'zh':'en'
  }
