import { defineStore } from 'pinia'
import { type WalletOption, connectWallet, disconnectWallet, generateWallets } from '@/wallets'
import type { MintheClient } from 'libminthe/libminthe'
import { Router, useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getLangParam } from '@/utils'

declare global{
  interface Window{
    router:Router
  }
}

export function isDev(){
  const meta = import.meta
  if(Object.keys(meta).includes("url")){
    return true
  }
  return false
}

export const useStore = defineStore('main', {
  state: () => ({
    debug: isDev(),
    route:useRoute(),
    lang:navigator.language,
    use_lang_id:getLangParam(),
    use_font: getLangParam() === 'zh' ? 'font-sans':'font-mono',
    isInitialized: false,
    wallets: generateWallets(isDev()) as WalletOption[],
    activate_wallet: undefined as WalletOption|undefined,
    show_wallet_model:false,
    wallet_public_key:"",
    mint_running:false,
    minthe_context:undefined as MintheClient|undefined,
    use_invite_code:"",
    invite_code_not_exists:false,
    use_fee_scalar:1.0,
    use_fee_id: "normal"
  }),

  actions: {
    async clickSwitchWallet(wallet:WalletOption){
      if(this.mint_running){
        return
      }
      console.log("clickSwitchWallet")
      if(wallet.adapter.connected&&wallet.adapter.publicKey){
        this.activate_wallet = wallet
        return
      }
      try{
        await connectWallet(wallet)
        if(wallet.adapter.connected){
          this.activate_wallet = wallet
          this.show_wallet_model = false
        }

      }catch(e:any){
        alert(`Error:${e}`)
      }
    },
    async clickdisconnectWallet(wallet:WalletOption){
      if(this.mint_running){
        return
      }
      console.log("disconnect")
      try{
        this.activate_wallet = undefined
        await disconnectWallet(wallet)
      }catch(e:any){
        alert(`Error:${e}`)
      }
    }
  },

  getters: {

  },
})
