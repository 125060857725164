import axios from "axios";

const pinataConfig = {
    root: 'https://api.pinata.cloud',
    headers: {
        'pinata_api_key': "6b94463f22b24e676f44",
        'pinata_secret_api_key': "dc6427e771388b9a68f4bbf16c77244525a13bd2a1089eed1ddb7533bf9f9a61"
    }
};


export async function uploadFileToIPFS(file: File) {
    if (file !== undefined) {
        const formData = new FormData();
        console.log(file)
        formData.append('file', file);
        const pinataBody = {
            options: {
                cidVersion: 1,
            },
            metadata: {
                name: file.name,
            }
        }
        formData.append('pinataOptions', JSON.stringify(pinataBody.options));
        formData.append('pinataMetadata', JSON.stringify(pinataBody.metadata));
        const url = `${pinataConfig.root}/pinning/pinFileToIPFS`;
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            headers: pinataConfig.headers
        })
        if (response.data["IpfsHash"]) {
            return `https://ipfs.io/ipfs/${response.data["IpfsHash"]}`
        }
    }
}

export async function uploadObjectToIPFS(obj: Object) {
    if (obj !== undefined) {
        const pinataBody = {
            options: {
                cidVersion: 1,
            },
            metadata: {
                name: "pinnie.json",
            }
        }
        const url = `${pinataConfig.root}/pinning/pinJsonToIPFS`;
        const response = await axios({
            method: 'post',
            url: url,
            data: {
                pinataOptions: pinataBody.options,
                pinataMetadata: pinataBody.metadata,
                pinataContent: obj
            },
            headers: {
                ...pinataConfig.headers,
                "Content-Type": "application/json"
            }
        })
        if (response.data["IpfsHash"]) {
            return `https://ipfs.io/ipfs/${response.data["IpfsHash"]}`
        }
    }
}